<template>
	<div class="store-body">
		<div class="store-head">
			<div class="store-head-main">
				<a href="/" class="logo"><img src="@/assets/images/seller/logo.png" alt=""></a>
				<div class="logout" @click="handleLogout">{{ $t('seller.header.exit') }}</div>
			</div>
		</div>
		<div class="store-r-body">
			<div class="store-r-main">
				<div class="store-r-title"><i class="ic-user"></i><h1>{{$t('seller.shop.title')}}</h1></div>
				<div class="store-r-box">
					<el-form :model="form" :rules="rules" ref="form">
						<el-form-item prop="storeName">
							<el-input v-model="form.storeName" maxlength="20" show-word-limit autocomplete="off" :placeholder="$t('seller.placeholder.shopName2')">
								<template slot="prefix">{{$t('seller.formItem.shopName2')}}</template>
							</el-input>
						</el-form-item>
						<el-form-item>
							<el-input v-model="userInfo.userName" disabled>
								<template slot="prefix">{{$t('seller.formItem.account')}}</template>
							</el-input>
						</el-form-item>
						<!-- <el-form-item>
							<el-input v-model="userInfo.mobile" disabled>
								<template slot="prefix">{{$t('seller.formItem.mobile')}}</template>
							</el-input>
						</el-form-item> -->
						<el-form-item prop="isAgreement">
							<el-checkbox v-model="form.isAgreement" class="uu_checkbox">{{$t('seller.checkbox.agreement')}}</el-checkbox>
						</el-form-item>
						<el-form-item>
							<el-button type="uu_red" @click="handleSubmit()">{{$t('seller.actions.applyOpenShop')}}</el-button>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex'
	import { applyStepFirst } from '@/api/seller/index';
	export default {
		computed: {
			...mapGetters([
				'userInfo'
			])
		},
		data() {
      var validateStoreName = (rule, value, callback) => {
        if (value === '') {
          callback(new Error(this.$t('seller.validator.shopName')));
        } else {
          callback();
        }
      };
			var validateAgreement = (rule, value, callback) => {
        if (value) {
          callback();
        } else {
          callback(new Error(this.$t('seller.validator.consentAgreement')));
        }
      };
			return {
				form: {
					storeName: '',
					isAgreement: false,
				},
				rules: {
					storeName: [
						{ validator: validateStoreName, trigger: 'blur' }
					],
					isAgreement: [
						{ validator: validateAgreement }
					]
				}
			}
		},
		created() {
		},
		methods: {
			handleSubmit(){
				let loading = this.$loading();
				this.$refs.form.validate((valid) => {
					if(valid){
						let param = {
							store_name: this.form.storeName,
							app_key: this.$store.getters.appKey
						}
						applyStepFirst(param).then(() => {
							loading.close();
							this.$store.dispatch('user/getInfo');
							this.$message.success(this.$t('seller.successMsg.authenticate'));
							this.$router.push({name:'openShop'});
						}).catch(() => {
							loading.close();
						})
					}else{
						loading.close();
						return false;
					}
				})
			},
			async handleLogout(){
				await this.$store.dispatch('user/logout')
				window.location.href = this.$loginBasePath + '?jump_url=' + window.location.href
			}
		}
	}
</script>

<style lang="scss" scoped>
	.store-body {
		position: relative;
		min-height: 100%;
		padding-bottom: 40px;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		.store-head {
			.store-head-main {
				width: 1200px;
				margin: 0 auto;
				padding: 10px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				.logo {
					display: inline-block;
					vertical-align: middle;
					img {
						height: 60px;
						display: block;
					}
				}
				.logout {
					color: #999;
					cursor: pointer;
					font-size: 16px;
					&:hover {
						color: $--seller-primary;
					}
				}
			}
		}
		.store-r-body {
			width: 400px;
			height: 480px;
			display: flex;
			padding: 0 50px;
			margin: 100px auto 0;
			justify-content: center;
			align-items: center;
			.store-r-main {
				width: 100%;
				.store-r-title {
					height: 26px;
					line-height: 26px;
					text-align: center;
					margin-bottom: 40px;
					.ic-user {
						width: 18px;
						height: 20px;
						display: inline-block;
						vertical-align: middle;
						background-image: url('~@/assets/images/seller/ic-user.png');
						background-repeat: no-repeat;
						background-position: center;
						background-size: 100% auto;
						margin-right: 10px;
					}
					h1 {
						display: inline-block;
						vertical-align: middle;
						font-size: 20px;
					}
				}
				.store-r-box {
					::v-deep .el-input--prefix {
						.el-input__inner {
							padding-left: 104px;
							border-radius: 20px;
							padding-right: 52px;
						}
						.el-input__prefix {
							left: 20px;
						}
					}
					::v-deep .el-input.is-disabled {
						.el-input__inner {
							background-color: #FFFFFF;
							color: #606266;
						}
					}
					::v-deep .uu_checkbox {
						color: #999999;
						.el-checkbox__input {
							.el-checkbox__inner {
								width: 20px;
								height: 20px;
								border-radius: 6px;
							}
							.el-checkbox__inner::after {
								left: 6px;
								height: 11px;
								width: 4px;
								border-right: 2px solid #FFFFFF;
								border-bottom: 2px solid #FFFFFF;
							}
						}
						.el-checkbox__input.is-checked {
							.el-checkbox__inner {
								background-color: #d9003d;
								border-color: #d9003d;
							}
						}
						.el-checkbox__input.is-checked + .el-checkbox__label {
							color: #999999;
						}
					}
					.el-button--uu_red {
						width: 100%;
						border-radius: 24px;
						font-size: 18px;
						padding: 15px 20px;
						color: #ffffff;
						background-color: #d9003d;
						border-color: #d9003d;
					}
					.el-button--uu_red:focus,.el-button--uu_red:hover {
						color: #ffffff;
						background-color: #d9003d;
						border-color: #d9003d;
					}
				}
			}
		}
	}
</style>
